import React, { Component } from "react";
import { Card } from "react-bootstrap";
import Switch from "react-switch";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "./sidebar.css";

class HintsSwitch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipVisible: props.tooltipVisible
    };
  }

  render() {
    return (
        <Card className="switch-card">
            <div className="menu-head">
              {this.state.tooltipVisible ?  
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 0, hide: 0 }}
                  key={"tooltip-hintSwitch"}
                  overlay={
                    <Tooltip className="icon-tooltip" id={`tooltip-hintSwitch`}>
                      <p>This is a hint. Hover over tools to find out what they do</p>
                    </Tooltip>
                  }
                >
                  <label htmlFor="material-switch">
                  <img className="header-icon" alt="" src={this.props.icon} />
                  Hints {this.state.tooltipVisible ? `On` : `Off`}
                    <Switch
                      checked={this.props.checked}
                      onChange={this.props.onChange}
                      onColor="#193e72"
                      onHandleColor="#ffffff"
                      handleDiameter={20}
                      height={15}
                      width={35}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      className="react-switch"
                      id="material-switch"
                    />
                  </label>
                </OverlayTrigger>
              : 
                <label htmlFor="material-switch">
                <img className="header-icon" alt="" src={this.props.icon} />

                Hints {this.state.tooltipVisible ? `On` : `Off`}
                  <Switch
                    checked={this.props.checked}
                    onChange={this.props.onChange}
                    onColor="#193e72"
                    onHandleColor="#ffffff"
                    handleDiameter={20}
                    height={15}
                    width={35}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    className="react-switch"
                    id="material-switch"
                  />
                </label>
              }
            </div>
        </Card>
    );
  }
}
export default HintsSwitch;
