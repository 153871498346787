import React, { Component } from "react";
import Moment from "react-moment";
import { Card, ListGroup, OverlayTrigger, Popover } from "react-bootstrap";
import "./sidebar.css";
import plus from "../../images/plus.svg";
import dots from "../../images/ellipsis.svg";
import clock from "../../images/clock.svg";
import bookmark from "../../images/bookmark.svg";
//import folder from "../../images/folder.svg";
import announcement from "../../images/announce.svg";
import externalLink from "../../images/external-link.svg";
import globe from "../../images/globe.svg";
import Accord from "./Accordion";
import arrow from "../../images/chevron-down.svg";
import rightarrow from "../../images/chevron-right.svg";
import infoCircle from "../../images/info-circle-solid.svg";
import "./sidebar.css";
import HintsSwitch from "./HintsSwitch";
import ReactHtmlParser from "html-react-parser";
const axios = require("axios");

//<a target="_blank" href="https://icons8.com/icons/set/plus">Plus icon</a> icon by <a target="_blank" href="https://icons8.com">Icons8</a>

class SidebarList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipVisible: false,
      recentSearches: [],
      savedSearches: [],
    };
  }

  async fetchSidebarData() {
    if (this.props.emailUser !== "") {
      try {
        const url1 = "https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fwww.nihr.ac.uk%2FAdmin%2FPost%2FSearchPosts.aspx%3Fid%3D182%26sitekit_SiteID%3D3413%26output%3Drss%26orderby%3DStartDate%2520DESC%26top%3D3";
        const res1 = await fetch(url1);
        const data1 = await res1.json();
        this.setState({ newsItemsArray: data1.items });
      } catch (error) {
        console.log("Error fetching data from news RSS:", error);
      }

      try {
        const url2 = "https://europe-west2-nihrhome.cloudfunctions.net/nihr-announcements";
        const res2 = await fetch(url2);
        const data2 = await res2.json();
        this.setState({ announcements: data2 });
      } catch (error) {
        console.log("Error fetching data from announcements:", error);
      }

      try {
        const url3 = "https://europe-west2-nihrhome.cloudfunctions.net/nihr-user-searches?userEmail=" + this.props.emailUser;
        const res3 = await fetch(url3);
        const data3 = await res3.json();
        this.setState({
          recentSearches: data3.recentSearches,
          savedSearches: data3.savedSearches,
        });
      } catch (error) {
        console.log("Error fetching data from saved searches:", error);
      }
    }
  }

  componentDidMount() {
    this.fetchSidebarData();
  }

  createNewsTable = () => {
    let table = [];
    if (this.state.newsItemsArray) {
      const items = this.state.newsItemsArray;
      for (let i = 0; i < 3; i++) {
        table.push(
          <ListGroup.Item key={items[i].title}>
            <a
              className="news-link"
              href={items[i].link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {items[i].title}
            </a>
          </ListGroup.Item>
        );
      }
    } else {
      table.push(<ListGroup.Item key={"NoNewsItem"}>No News</ListGroup.Item>);
    }
    return table;
  };

  createAnnounceTable = () => {
    let table = [];
    if (this.state.announcements) {
      const announcementItems = this.state.announcements;
      announcementItems.map((item, index) => {
        table.push(
          <ListGroup.Item key={item.title + index}>
            {ReactHtmlParser(item.content)}
          </ListGroup.Item>
        );
        return table;
      });
    } else {
      table.push(
        <ListGroup.Item key={"NoAnnouncementsItem"}>
          No Announcements
        </ListGroup.Item>
      );
    }
    return table;
  };

  // createDriveTable = () => {
  //   let table = [];
  //   table.push(<ListGroup.Item key={"Files"}>No Files</ListGroup.Item>);
  //   return table;
  // };

  createRecentSearchesTable = () => {
    let table = [];
    let recent = this.state.recentSearches;
    if (recent.length > 0) {
      for (let i = 0; i < recent.length; i++) {
        let a = JSON.parse(recent[i]);
        table.push(
          <ListGroup.Item key={a.id + "recent"}>
            <div className="recent-search-group">
              <div className="search-time">
                <Moment format="HH:mm">{a.searchDate}</Moment> <br></br>
                <Moment format="DD-MM-YY">{a.searchDate}</Moment>
              </div>
              <div className="search-item">
                <a href={a.url} target="_blank" rel="noopener noreferrer">
                  {a.searchValue}
                </a>

                <span className="search-url">Google Cloud Search</span>
              </div>
              <OverlayTrigger
                trigger="click"
                key="right"
                placement="right"
                rootClose
                overlay={
                  <Popover className="shadow mb-5">
                    <Popover.Content>
                      <div className="popover-ul-link">
                        <li>
                          <button
                            className="search-link"
                            onClick={this.AddSearchToSavedSearches.bind(
                              this,
                              a.id,
                              a.userEmail,
                              a.searchValue,
                              a.searchDate,
                              a.url,
                              a.recent
                            )}
                          >
                            Save Search
                          </button>
                        </li>
                        <li>
                          <button
                            className="search-link"
                            onClick={this.removeRecentSearch.bind(
                              this,
                              a.id,
                              a.userEmail,
                              a.searchValue,
                              a.searchDate,
                              a.url,
                              a.saved
                            )}
                          >
                            Remove from Recent Searches
                          </button>
                        </li>
                      </div>
                    </Popover.Content>
                  </Popover>
                }
              >
                <div className="icon">
                  <img className="plus" alt="plus" src={plus} />
                </div>
              </OverlayTrigger>
            </div>
          </ListGroup.Item>
        );
      }
      if (table.length === 0) {
        table.push(
          <ListGroup.Item key={"NoSavedSearches"}>
            No Saved Searches
          </ListGroup.Item>
        );
      }
    } else {
      table.push(
        <ListGroup.Item key={"NoSavedSearches"}>
          No Saved Searches
        </ListGroup.Item>
      );
    }
    return table;
  };

  createSavedSearchesTable = () => {
    let table = [];
    let saved = this.state.savedSearches;
    if (saved.length > 0) {
      for (let i = 0; i < saved.length; i++) {
        let b = JSON.parse(saved[i]);
        table.push(
          <ListGroup.Item key={b.id + "saved"}>
            <div className="recent-search-group">
              <div className="search-time">
                <Moment format="HH:mm">{b.searchDate}</Moment> <br></br>
                <Moment format="DD-MM-YY">{b.searchDate}</Moment>
              </div>
              <div className="search-item">
                <a href={b.url} target="_blank" rel="noopener noreferrer">
                  {b.searchValue}
                </a>
                <span className="search-url">Google Cloud Search</span>
              </div>
              <OverlayTrigger
                trigger="click"
                key="right"
                placement="right"
                rootClose
                overlay={
                  <Popover className="shadow mb-5">
                    <Popover.Content>
                      <div className="popover-ul-link">
                        <li>
                          <a
                            className="save-search-link"
                            href={b.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            tabIndex="0"
                          >
                            Open Link
                          </a>
                        </li>
                        <li>
                          <button
                            id="remove"
                            className="search-link"
                            onClick={this.removeSavedSearch.bind(
                              this,
                              b.id,
                              b.userEmail,
                              b.searchValue,
                              b.searchDate,
                              b.url,
                              b.recent
                            )}
                          >
                            Remove from Saved Searches
                          </button>
                        </li>
                      </div>
                    </Popover.Content>
                  </Popover>
                }
              >
                <div className="icon">
                  <img className="dots" alt="popover target" src={dots} />
                </div>
              </OverlayTrigger>
            </div>
          </ListGroup.Item>
        );
      }
      if (table.length === 0) {
        table.push(
          <ListGroup.Item key={"NoSavedSearches"}>
            No Saved Searches
          </ListGroup.Item>
        );
      }
    } else {
      table.push(
        <ListGroup.Item key={"NoSavedSearches"}>
          No Saved Searches
        </ListGroup.Item>
      );
    }
    return table;
  };

  AddSearchToSavedSearches = (
    id,
    userEmail,
    searchValue,
    searchDate,
    url,
    recent
  ) => {
    axios({
      method: "POST",
      url:
        "https://europe-west2-nihrhome.cloudfunctions.net/nihr-user-searches?id=" +
        id,
      data: {
        userEmail: userEmail,
        searchValue: searchValue,
        searchDate: searchDate,
        url: url,
        recent: recent,
        saved: true,
      },
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        this.updateRecords();
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });

    document.getElementById("listSaved Searches").className = "collapse show";
  };

  removeRecentSearch = (id, userEmail, searchValue, searchDate, url, saved) => {
    axios({
      method: "POST",
      url:
        "https://europe-west2-nihrhome.cloudfunctions.net/nihr-user-searches?id=" +
        id,
      data: {
        userEmail: userEmail,
        searchValue: searchValue,
        searchDate: searchDate,
        url: url,
        recent: false,
        saved: saved,
      },
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        this.updateRecords();
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  removeSavedSearch = (id, userEmail, searchValue, searchDate, url, recent) => {
    axios({
      method: "POST",
      url:
        "https://europe-west2-nihrhome.cloudfunctions.net/nihr-user-searches?id=" +
        id,
      data: {
        userEmail: userEmail,
        searchValue: searchValue,
        searchDate: searchDate,
        url: url,
        recent: recent,
        saved: false,
      },
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        this.updateRecords();
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  updateRecords() {
    axios({
      method: "GET",
      url:
        "https://europe-west2-nihrhome.cloudfunctions.net/nihr-user-searches?userEmail=" +
        this.props.emailUser,
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        this.setState({
          recentSearches: response.data.recentSearches,
          savedSearches: response.data.savedSearches,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleSliderChange = () => {
    this.props.setTooltip(!this.state.tooltipVisible);
    this.state.tooltipVisible
      ? this.setState({ tooltipVisible: false })
      : this.setState({ tooltipVisible: true });
  };

  render() {
    return (
      <div className="sidebar">
        <Card className="TitleCard">
          <Card.Header>
            <a
              className="support-link"
              href="https://support.nihr.ac.uk/nihr/login_with_sso.do?glide_sso_id=771b6fbe1b215110f9512060f54bcb04"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="support">
                Go to Support
                <img
                  className="right-chev"
                  alt="support page"
                  src={externalLink}
                />
              </div>
            </a>
          </Card.Header>
        </Card>

        <Accord
          title="NIHR News"
          icon={globe}
          content={this.createNewsTable()}
          defaultActive="0"
          open="true"
          img={arrow}
          eventKey="0"
        />

        <Accord
          title="NIHR Announcements"
          icon={announcement}
          defaultActive="1"
          content={this.createAnnounceTable()}
          open="true"
          img={arrow}
          eventKey="1"
        />
        <Accord
          title="Recent Searches"
          icon={clock}
          content={this.createRecentSearchesTable()}
          open="false"
          img={rightarrow}
          eventKey="2"
        />

        <Accord
          title="Saved Searches"
          icon={bookmark}
          content={this.createSavedSearchesTable()}
          open="false"
          img={rightarrow}
          eventKey="3"
        />

        {/* <Accord
          title="Recent Files"
          icon={folder}
          content={this.createDriveTable()}
          open="false"
          img={rightarrow}
          eventKey="4"
        /> */}

        <HintsSwitch
          icon={infoCircle}
          tooltipVisible={this.state.tooltipVisible}
          checked={this.state.tooltipVisible}
          onChange={this.handleSliderChange}
        />
      </div>
    );
  }
}
export default SidebarList;
