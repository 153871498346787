import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import "./footer.css";

class Footer extends Component {
  render() {
    return (
        <Row className="footer-content no-gutters">
          <Col id="pagesCol" xl="2" lg="1" md="6" sm="6" xs="12">
              <a
                className="footer-link"
                href="https://www.nihr.ac.uk/about-us"
                target="_blank"
                rel="noopener noreferrer"
              >
                About Us
              </a>
              <a
                className="footer-link"
                href="https://www.nihr.ac.uk/about-us/contact-us/contact-us.htm"
                target="_blank"
                rel="noopener noreferrer"
              >
                Contact
              </a>
          </Col>
          <Col id="termsCol" xl="2" lg="2" md="6" sm="6" xs="12">
              <a
                className="footer-link"
                href="https://docs.google.com/document/edit?hgd=1&id=1rhWwSkt7DtdNAL8Jz4eNciP5S-F-fxsLST45fwBK-Gs"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms & Acceptable Use
              </a>
              <a
                className="footer-link"
                href="https://sites.google.com/nihr.ac.uk/nihr-hub-accessibility/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Accessibility
              </a>
          </Col>
          <Col id="descCol" xl="5" lg="5" md="6" sm="6" xs="12">
            <div className="footer-text">
              <p>
                We fund, enable and deliver world-leading health and social care research that improves people's health and wellbeing, and promotes economic growth.
              </p>
            </div>
          </Col>
          <Col id="copyrightCol" xl="3" lg="4" md="6" sm="6" xs="12">
            <div className="copyright">
              <p>Copyright @ National Institute for Health and Care Research
              <a
                  className="footer-link"
                  href="https://www.nihr.ac.uk/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.nihr.ac.uk
                </a>
              </p>

            </div>
          </Col>
        </Row>
    );
  }
}
export default Footer;
