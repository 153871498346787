import React, { useState } from "react";
import ToolsStore from "./ToolsStore";
import Icon from "./Icon";
import update from "immutability-helper";
import "./Tools.css";
import { Container, Row, Col, Accordion, Button } from "react-bootstrap";
import chevron from "../../../images/chevron-down-black.svg";
import rightchev from "../../../images/chevron-right-black.svg";

const axios = require("axios");

const ToolsDisplay = (props) => {
  let userPrefObj = JSON.parse(window.localStorage.getItem("userPref"));
  const pinnedTools = [props.iconLinks[0], props.iconLinks[1], props.iconLinks[17]];

  if (!userPrefObj) {
    userPrefObj = {};
  }

  if (!userPrefObj.tools) {
    userPrefObj.favTools = pinnedTools;
    userPrefObj.tools = [];
    for (let i = 0; i < props.iconLinks.length; i++) {
      if(!userPrefObj.favTools.includes(props.iconLinks[i])){
        userPrefObj.tools.push(props.iconLinks[i]);
      }
    }
  }

  const hiddenTools = ["15","18","24"];
  const storedTools = userPrefObj.tools;
  const storedFavTools = userPrefObj.favTools;
  const toolTipState = props.tooltipVisible;
  const emailState = props.emailUser;

  const [icons, setIcons1] = useState(userPrefObj.favTools);
  const [icons2, setIcons2] = useState(userPrefObj.tools);
  let [displayTools, setDisplayTools] = useState(0);
  const [droppedIconNames] = useState([]);

  function isDropped(iconName) {
    return droppedIconNames.indexOf(iconName) > -1;
  }

  const handleDrop = (index, item) => {
    let itemLocation;

    if (!item.name) {
      return;
    }

    if (index === 0) {
      itemLocation = storedTools.find((element) => element.id === item.name.id);
    } else if (index === 1) {
      itemLocation = storedFavTools.find(
        (element) => element.id === item.name.id
      );
    }

    if (itemLocation && index === 0 && storedFavTools.length < 12) {
      storedFavTools.push(item.name);
      const searched = (element) => element.id === item.name.id;
      const indexSplice = storedTools.findIndex(searched);
      if (indexSplice > -1) {
        storedTools.splice(indexSplice, 1);
        window.localStorage.setItem("userPref", JSON.stringify(userPrefObj));

        postTools(storedFavTools);

        setIcons1(
          update(icons, {
            $splice: [[indexSplice, 0]],
          })
        );
      }
    } else if (itemLocation && index === 1) {
      storedTools.push(item.name);
      const searched = (element) => element.id === item.name.id;
      const indexSplice = storedFavTools.findIndex(searched);

      if (indexSplice > -1) {
        storedFavTools.splice(indexSplice, 1);
      }
      window.localStorage.setItem("userPref", JSON.stringify(userPrefObj));
      postTools(storedFavTools);
      setIcons2(
        update(icons2, {
          $splice: [[indexSplice, 0]],
        })
      );
    }
  };

  function togglePanel(e) {
    setDisplayTools(
      update(displayTools, {
        $set: displayTools === 0 ? 1 : 0,
      })
    );
  }

  const createIconList = (icons, section) => {
    let tools = [];
    if (section === "Tools") {
      icons.forEach(function (item, index) {
        if(!hiddenTools.includes(item.id)){
          tools.push(
            <Icon
              name={item}
              type={"icon"}
              isDropped={isDropped(item)}
              item={item}
              key={"iconTools" + index}
              section={section}
              index={"iconTools" + index}
              toolTipStatus={toolTipState}
            />
          );
        }
      });
    } else if (section === "FavTools") {
      for (let i = 0; i < 12; i++) {
        if (!icons[i] || hiddenTools.includes(icons[i].id)) {
            tools.push(
              <Icon
                name={undefined}
                type={"empty"}
                isDropped={isDropped(undefined)}
                item={undefined}
                key={"iconFav" + i}
                section={section}
                index={"iconFav" + i}
                toolTipStatus={toolTipState}
              />
            );
        } else {
          if (!hiddenTools.includes(icons[i].id)){
              tools.push(
              <Icon
                name={icons[i]}
                type={"icon"}
                isDropped={isDropped(icons[i])}
                item={icons[i]}
                key={"iconFav" + i}
                section={section}
                index={"iconFav" + i}
                toolTipStatus={toolTipState}
              />
            );
        }
      }
      }
    }
    return tools;
  };

  function postTools(tools) {
    if (emailState !== "") {
      axios({
        method: "POST",
        url: "https://europe-west2-nihrhome.cloudfunctions.net/nihr-user-preferences",
        data: {
          userId: emailState,
          favTools: extractToolIds(tools),
        },
        headers: { "Content-Type": "application/json" },
      })
        .then((response) => {
          //preferences updated
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  const extractToolIds = (iconList) => {
    let toolIds = [];
    iconList.forEach(function (item) {
      toolIds.push(item.id);
    });
    return toolIds;
  };

  return (
    <Container className="Tool-container">
      <Row className="justify-content-md-center">
        <Col xl="11" lg="11" md="12">
          <div>
            <div id="favourites" className="dropTarget">
              <ToolsStore
                accepts={"icon"}
                lastDroppedItem={null}
                onDrop={(item) => handleDrop(0, item)}
                key={0}
              />
              {createIconList(storedFavTools, "FavTools")}
            </div>
            <Accordion defaultActiveKey="0">
              <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey="0"
                onClick={(e) => togglePanel(e)}
                className="header toggleTools"
              >
                {displayTools === 0 ? (
                  <h2 className="tools-title">
                    Hide Tools
                    <img className="chev" alt="down arrow" src={chevron} />
                  </h2>
                ) : (
                  <h2 className="tools-title">
                    Show Tools
                    <img className="chev" alt="down arrow" src={rightchev} />
                  </h2>
                )}
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <div id="allTools" className="dropTarget">
                  <p className="tools-text">
                    Drag and drop your favourite tools to your dashboard for
                    easy access
                  </p>
                  <ToolsStore
                    accepts={"icon"}
                    lastDroppedItem={null}
                    onDrop={(item) => handleDrop(1, item)}
                    key={1}
                  />
                  {createIconList(storedTools, "Tools")}
                </div>
              </Accordion.Collapse>
            </Accordion>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default ToolsDisplay;
