import React, { Component } from "react";
import { Form, Button, InputGroup, Col, Row, Container } from "react-bootstrap";
import "./aup.css";

const acceptAup = (email) => {
  if (email.length > 1) {
    var d = new Date();
    d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString() + ";";
    let encodeEmail = btoa(email);
    document.cookie = "AUPCookie=accepted_" + encodeEmail + ";" + expires;
    window.location.reload(true);
  } else {
    var originalURL = window.location.href;
    const splitURLArray = originalURL.split("?signedIn=true");
    let baseURL = splitURLArray[0];
    window.location.href = baseURL;
  }
};

class Main extends Component {
  signOut() {
    localStorage.removeItem("signedInUser");
    window.location.reload(true);
  }

  render() {
    return (
      <div className="main-div">
        <Container>
          <Row>
            <Col className="aup-position">
              <Form
                target="_blank"
                rel="noopener noreferrer"
                onSubmit={this.handleSubmit}
                id="aup-form"
              >
                <InputGroup className="displayBlock aup-position">
                  <p className="aup-position">
                    Please read and accept the
                    <a
                      aria-label="Acceptable Use Policy - (Opens in a new tab)"
                      target="_blank"
                      href="https://docs.google.com/document/d/e/2PACX-1vS4NiC8UW-64rZhDJsO2uGbhfWjoKu52QrfRWbDkAqI3cLvl3HUryRJ8GIAXYVhT9LdtqyIZxVGlY2L/pub?"
                      rel="noopener noreferrer"
                    >
                      &nbsp;Acceptable Use Policy&nbsp;
                    </a>{" "}
                    before you continue using the site
                  </p>
                  <iframe
                    title="NIHR Hub Acceptable Use Policy"
                    className="AUP-frame aup-position"
                    src="https://docs.google.com/document/d/e/2PACX-1vS4NiC8UW-64rZhDJsO2uGbhfWjoKu52QrfRWbDkAqI3cLvl3HUryRJ8GIAXYVhT9LdtqyIZxVGlY2L/pub?embedded=true"
                  ></iframe>
                  <InputGroup.Append className="aup-position">
                    <Button
                      id="aup-decline-button"
                      className="aup-button g_id_signout"
                      onClick={() => this.signOut()}
                    >
                      Decline
                    </Button>
                    <Button
                      id="aup-accept-button"
                      className="aup-button"
                      onClick={() => acceptAup(this.props.emailUser)}
                    >
                      Accept
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
export default Main;
