import sheets from "../../../images/iconsLinks/sheets.png";
import forms from "../../../images/iconsLinks/forms.png";
import keep from "../../../images/iconsLinks/keep.png";
import chat from "../../../images/iconsLinks/chat.png";
import slides from "../../../images/iconsLinks/slides.png";
import groups from "../../../images/iconsLinks/groups.png";
import contacts from "../../../images/iconsLinks/contacts.png";
import support from "../../../images/iconsLinks/support.png";
import directory from "../../../images/iconsLinks/directory_new.png";
import info from "../../../images/iconsLinks/info.png";
import tips from "../../../images/iconsLinks/tips.png";
import induction from "../../../images/iconsLinks/nihrcirclelogo.png";
import nihrItPoliciesAndProcedures from "../../../images/iconsLinks/nihr-it-policies-and-procedures.png";
import eduroam from "../../../images/iconsLinks/wifi.png";
import hybridWorkingPortal from "../../../images/iconsLinks/HybridIcon.png";
import sites from "../../../images/iconsLinks/sites.png";
import kanbanchi from "../../../images/iconsLinks/kanbanchi.png";
import lucid from "../../../images/iconsLinks/lucidchart.png";
import drive from "../../../images/iconsLinks/drive.png";
import calendar from "../../../images/iconsLinks/calendar.png";
import hangouts from "../../../images/iconsLinks/meet.png";
import docs from "../../../images/iconsLinks/docs.png";
import gmail from "../../../images/iconsLinks/gmail.png";
import googlecurrents from "../../../images/iconsLinks/googleCurrents.png";
import learn from "../../../images/iconsLinks/nihrlearn.png";
import jira from "../../../images/iconsLinks/jira.png";
import liveLearning from "../../../images/iconsLinks/live-learning.png";
import confluence from "../../../images/iconsLinks/confluence.png";

const iconLinks = [
  {
    img: directory,
    link: "https://mycloudpages.appspot.com/?hd=nihr.ac.uk&from=google#home",
    alt: "NIHR directory",
    desc: "Directory",
    tooltip: "Find the contact details of NIHR personnel",
    id: "0",
  },
  {
    img: support,
    link: "https://support.nihr.ac.uk/nihr/login_with_sso.do?glide_sso_id=771b6fbe1b215110f9512060f54bcb04",
    alt: "NIHR Support",
    desc: "Support",
    tooltip: "Access the portal for help with NIHR Hub technical issues",
    id: "1",
  },
  {
    img: drive,
    link: "https://drive.google.com/a/nihr.ac.uk",
    alt: "Google Drive",
    desc: "Drive",
    tooltip: "View, open and share your documents and files",
    id: "2",
  },
  {
    img: calendar,
    link: "https://calendar.google.com",
    alt: "Google Calendar",
    desc: "Calendar",
    tooltip: "Keep on top of upcoming meetings and events",
    id: "3",
  },
  {
    img: hangouts,
    link: "https://meet.google.com/",
    alt: "Google Meet",
    desc: "Meet",
    tooltip: "Start and join voice and video calls",
    id: "4",
  },
  {
    img: docs,
    link: "https://docs.google.com/document/u/0/",
    alt: "Google Docs",
    desc: "Docs",
    tooltip: "Create, edit and share text-based documents",
    id: "5",
  },
  {
    img: gmail,
    link: "https://mail.google.com/a/nihr.ac.uk",
    alt: "Google Mail",
    desc: "Gmail",
    tooltip: "Access your email",
    id: "6",
  },
  {
    img: googlecurrents,
    link: "https://currents.google.com",
    alt: "Google Currents",
    desc: "Currents",
    tooltip: "Access your Google communities",
    id: "7",
  },
  {
    img: sheets,
    link: "https://docs.google.com/spreadsheets/u/0/",
    alt: "Google Sheets",
    desc: "Sheets",
    tooltip: "Create, edit and share spreadsheets",
    id: "8",
  },
  {
    img: forms,
    link: "https://docs.google.com/forms/u/0/",
    alt: "Google forms",
    desc: "Forms",
    tooltip: "Create, edit and share questionnaires/surveys",
    id: "9",
  },
  {
    img: keep,
    link: "https://keep.google.com/",
    alt: "Google Keep",
    desc: "Keep",
    tooltip: "Capture notes and share them with others",
    id: "10",
  },
  {
    img: chat,
    link: "https://chat.google.com/",
    alt: "Google Chat",
    desc: "Chat",
    tooltip: "Send instant messages to your colleagues",
    id: "11",
  },
  {
    img: slides,
    link: "https://docs.google.com/presentation/u/0/",
    alt: "Google Slides",
    desc: "Slides",
    tooltip: "Create, edit and share presentations",
    id: "12",
  },
  {
    img: groups,
    link: "http://groups.nihr.ac.uk/",
    alt: "Google Groups",
    desc: "Groups",
    tooltip:
      "Create and participate in community conversations in online forums and email-based groups",
    id: "13",
  },
  {
    img: contacts,
    link: "https://www.google.com/contacts",
    alt: "Google Contacts",
    desc: "Contacts",
    tooltip: "Access your saved contacts",
    id: "14",
  },
  {
    img: info,
    link: "https://sites.google.com/nihr.ac.uk/informationhub",
    alt: "Information hub",
    desc: "Info Hub",
    tooltip: "Access key information on the tools that are available to you",
    id: "15",
  },

  {
    img: tips,
    link: "https://currents.google.com/communities/112882898318518246910",
    alt: "Tips and tricks",
    desc: "Tips & Tricks",
    tooltip:
      "Access the Tips & Tricks G+ community to create and follow announcements",
    id: "16",
  },
  {
    img: induction,
    link: "https://sites.google.com/nihr.ac.uk/nihr-induction-and-materials/home",
    alt: "NIHR Info for staff",
    desc: "NIHR Info for staff",
    tooltip: "Your staff information hub",

    id: "17",
  },
  {
    img: nihrItPoliciesAndProcedures,
    link: "https://sites.google.com/nihr.ac.uk/gdpr/home",
    alt: "NIHR IT Policies and Procedures",
    desc: "Policies & Procedures",
    tooltip: "Access information on NIHR policies and procedures",
    id: "18",
  },
  {
    img: learn,
    link: "https://learn.nihr.ac.uk/",
    alt: "NIHR Learn link",
    desc: "NIHR Learn",
    tooltip: "Access courses and NIHR learning materials",
    id: "19",
  },

  {
    img: sites,
    link: "https://sites.google.com/new?usp=jotspot_si",
    alt: "Google Sites",
    desc: "Sites",
    tooltip: "Create a website to share information with others",
    id: "20",
  },
  {
    img: kanbanchi,
    link: "https://kanban-chi.appspot.com/",
    alt: "Kanbanchi link",
    desc: "Kanbanchi",
    tooltip: "Manage your tasks and projects collaboratively",
    id: "21",
  },
  {
    img: lucid,
    link: "https://lucid.app/documents#/dashboard",
    alt: "Lucidchart & Lucidspark link",
    desc: "Lucidchart & Lucidspark",
    tooltip: "Create charts and diagrams",
    id: "22",
  },
  {
    img: eduroam,
    link: "https://eduroam.nihr.ac.uk/",
    alt: "Eduroam Wifi",
    desc: "Eduroam",
    tooltip: "Find out how to connect to Eduroam WiFi networks",
    id: "23",
  },
  {
    img: hybridWorkingPortal,
    link: "https://sites.google.com/nihr.ac.uk/hybrid-working-portal/home",
    alt: "NIHR hybrid working Portal",
    desc: "Hybrid Working Portal",
    tooltip: "Information on Workspace features supporting hybrid working",
    id: "24",
  },
  {
    img: jira,
    link: "https://nihr.atlassian.net/jira/",
    alt: "NIHR Jira",
    desc: "NIHR Jira",
    tooltip: "Access key NIHR information within Jira",
    id: "25",
  },
  {
    img: confluence,
    link: "https://nihr.atlassian.net/wiki/",
    alt: "NIHR Confluence",
    desc: "NIHR Confluence",
    tooltip: "Access the NIHR Confluence portal for documentation",
    id: "26",
  },
  {
    img: liveLearning,
    link: "https://training.ceyx.app/NIHR/",
    alt: "Live Learning",
    desc: "Live Learning",
    tooltip: "Google Training",
    id: "27",
  }
];

export default iconLinks;