import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import iconsLinks from "./IconsLinks";
import "./Tools.css";
import ToolsDisplay from "./ToolsDisplay";
import { DndProvider } from "react-dnd";
import Backend from "react-dnd-html5-backend";
import TouchBackend from "react-dnd-touch-backend";
import { BrowserView, MobileView } from "react-device-detect";

const axios = require("axios");

class Tools extends Component {
  constructor(props) {
    super(props);
    this.state = {
      iconLinks: iconsLinks,
      storedTools: [],
      otherTools: [],
      getTools: false,
    };
  }

  getStoredTools(userEmail, iconLinks) {
    let storedTools = [];
    let otherTools = iconLinks;
    axios({
      method: "GET",
      url:
        "https://europe-west2-nihrhome.cloudfunctions.net/nihr-user-preferences?userId=" +
        userEmail,
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        const toolIds = response.data[0].favTools;

        toolIds.forEach(function (item) {
          iconLinks.forEach(function (prop, index) {
            if (item === prop.id) {
              storedTools.push(prop);
            }
          });
        });

        toolIds.sort((a, b) => a - b);

        for (var i = toolIds.length - 1; i >= 0; i--) {
          otherTools.splice(toolIds[i], 1);
        }

        let loadedPreferences = {
          favTools: storedTools,
          tools: otherTools,
        };

        if (loadedPreferences.favTools.length > 0) {
          window.localStorage.setItem(
            "userPref",
            JSON.stringify(loadedPreferences)
          );
        }

        this.setState({
          getTools: true,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    const tooltipVisible = this.props.tooltipVisible;
    const emailUser = this.props.emailUser;

    if (!this.state.getTools && emailUser !== "") {
      this.getStoredTools(emailUser, this.state.iconLinks);
    }

    return (
      <div>
        <Row>
          <Col>
            <BrowserView>
              <DndProvider backend={Backend}>
                <ToolsDisplay
                  iconLinks={iconsLinks}
                  tooltipVisible={tooltipVisible}
                  emailUser={emailUser}
                />
              </DndProvider>
            </BrowserView>
            <MobileView>
              <DndProvider backend={TouchBackend}>
                <ToolsDisplay
                  iconLinks={iconsLinks}
                  tooltipVisible={tooltipVisible}
                  emailUser={emailUser}
                />
              </DndProvider>
            </MobileView>
          </Col>
        </Row>
      </div>
    );
  }
}
export default Tools;
