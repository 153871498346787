import React from "react";
import { DropTarget } from "react-dnd";
import "./Tools.css";

export const ToolsStore = ({
  accepts,
  isOver,
  canDrop,
  connectDropTarget,
  lastDroppedItem
}) => {
  const isActive = isOver && canDrop;
  const canDropTarget = isActive ? "99" : "0";

  return connectDropTarget(
    <div className="toolsStore" style={{ zIndex: canDropTarget }}>
      {lastDroppedItem && (
        <p>Last dropped: {JSON.stringify(lastDroppedItem)}</p>
      )}
    </div>
  );
};

export default DropTarget(
  props => props.accepts,
  {
    drop(props, monitor) {
      props.onDrop(monitor.getItem());
    }
  },
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
  })
)(ToolsStore);
