import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Col, Row, Container } from "react-bootstrap";
import Header from "./components/header/header";
import Sidebar from "./components/sidebar/sidebar";
import Footer from "./components/footer/footer";
import Main from "./components/main/main";
import Aup from "./components/main/Aup/aup";
import Announcements from "./components/main/announcements";
import "./App.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const announcements = (emailUser) => {
  let announcement = [];
  const authAccounts = [
    "adam.davis@nihr.ac.uk",
    "simon.brewer@nihr.ac.uk",
    "jack.mcmenzie@nihr.ac.uk",
    "kevin.officer@nihr.ac.uk",
    "tanisha.walker@nihr.ac.uk",
  ];
  authAccounts.forEach((item) => {
    if (item === emailUser) {
      announcement.push(<Announcements key={"announcementComponent"} />);
    }
  });
  return announcement;
};

const App = () => {
  const [tokenClient, setTokenClient] = useState();
  const [token, setToken] = useState("");
  const [hasAcceptedAUPBool, setHasAcceptedAUPBool] = useState(false);
  const [tooltip, setTooltip] = useState(false);
  const [signedInUser, setSignIn] = useState({
    status: false,
    emailUser: "",
    username: "",
  });

  useEffect(() => {
    var signedInUserObj = JSON.parse(window.localStorage.getItem("signedInUser"));

    if (signedInUserObj == null) {
      signedInUserObj = { status: "false", emailUser: "" };
    }

    if (signedInUserObj != null && signedInUserObj.emailUser != null) {
      if (
        signedInUser.emailUser !== signedInUserObj.emailUser ||
        signedInUser.status !== signedInUserObj.status
      ) {
        setSignIn(signedInUserObj);
      }
    }

    if (!hasAcceptedAUPBool && signedInUserObj.emailUser != null) {
      hasAcceptedAUP(signedInUserObj.emailUser);
    }
  }, [signedInUser, hasAcceptedAUPBool]);

  useEffect(() => {
    var clientId = "166012030281-r1bmq6i6is86kf60vn3lrkkqjo1ukib2.apps.googleusercontent.com";
    var client = window.google.accounts.oauth2.initTokenClient({
      client_id: clientId,
      scope: "profile email openid",
      callback: (tokenResponse) => {
        setToken(tokenResponse.access_token);
      },
    });

    setTokenClient(client);
  }, [token]);

  const hasAcceptedAUP = (email) => {
    let cookie = document.cookie.split(";");
    let AUPCookie;
    let encodedEmail = btoa(email);

    for (let i = 0; i < cookie.length; i++) {
      let c = cookie[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf("AUPCookie=") === 0) {
        AUPCookie = c.substring("AUPCookie=".length);
        if (encodedEmail === AUPCookie.split("_")[1]) {
          setHasAcceptedAUPBool(true);
          break;
        }
      }
    }
  };

  const signOut = () => {
    localStorage.removeItem("signedInUser");
    window.location.reload(true);
  };

  const handleSignin = (content) => {
    setSignIn({
      status: content.verified_email,
      emailUser: content.email,
      username: content.given_name + " " + content.family_name,
    });

    const tempSignedInUser = {
      status: `${content.verified_email}`,
      emailUser: `${content.email}`,
      username: `${content.given_name} ${content.family_name}`,
    };

    window.localStorage.setItem(
      "signedInUser",
      JSON.stringify(tempSignedInUser)
    );

    window.location.reload(true);
  };

  const getToken = () => {
    tokenClient.requestAccessToken();
  };

  function signIn() {
    fetch("https://www.googleapis.com/oauth2/v1/userinfo?alt=json", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((content) => handleSignin(content))
      .catch((err) => {
        console.log(err);
      });
  }

  if (signedInUser.status === false) {
    // no body
    localStorage.removeItem("userPref");
    return (
      <Router>
        <div>
          <Container fluid={true} className="AppBody">
            <Row noGutters={true}>
              <Col>
                <Header signedInUser={signedInUser} />
                {signedInUser.emailUser == null ? (
                  <p className="SignOut">
                    <button className="signOutButton" onClick={() => signIn()}>
                      Sign in
                    </button>
                  </p>
                ) : (
                  <p className="SignOut">
                    Please authorise your account{" "}
                    <button
                      className="signOutButton"
                      onClick={() => getToken()}
                    >
                      Authorise
                    </button>
                  </p>
                )}
              </Col>
            </Row>
            <Row noGutters={true} className="MainRow">
              <Col xl="3" lg="3" md="4" sm="4" className="SidebarCol">
                <Sidebar
                  setTooltip={setTooltip}
                  emailUser={signedInUser.emailUser}
                />
              </Col>
              <Col xl="9" lg="9" md="8" sm="8" className="MainCol">
                <Route exact path="/announcements">
                  {announcements(signedInUser.emailUser)}
                </Route>
              </Col>
            </Row>
            <Row noGutters={true}>
              <Col>
                <Footer />
              </Col>
            </Row>
          </Container>
        </div>
      </Router>
    );
  } else {
    if (signedInUser.status && hasAcceptedAUPBool) {
      // signed in and accepted
      return (
        <Router>
          <div>
            <Container fluid={true} className="AppBody">
              <Row noGutters={true}>
                <Col>
                  <Header signedInUser={signedInUser} />
                  {signedInUser.emailUser.length > 0 && (
                    <p className="SignOut">
                      {signedInUser.username}{" "}
                      <button
                        className="signOutButton"
                        onClick={() => signOut()}
                      >
                        Sign Out
                      </button>
                    </p>
                  )}
                </Col>
              </Row>
              <Row noGutters={true} className="MainRow">
                <Col xl="3" lg="3" md="4" sm="4" className="SidebarCol">
                  <Sidebar
                    setTooltip={setTooltip}
                    emailUser={signedInUser.emailUser}
                  />
                </Col>
                <Col xl="9" lg="9" md="8" sm="8" className="MainCol">
                  <Switch>
                    <Main
                      tooltipVisible={tooltip}
                      emailUser={signedInUser.emailUser}
                    />
                    <Route exact strict path="/announcements">
                      {announcements(signedInUser.emailUser)}
                    </Route>
                  </Switch>
                </Col>
              </Row>
              <Row noGutters={true}>
                <Col>
                  <Footer />
                </Col>
              </Row>
            </Container>
          </div>
        </Router>
      );
    } else if (
      signedInUser.status &&
      !hasAcceptedAUP(signedInUser.emailUser) &&
      signedInUser.emailUser != null &&
      signedInUser.emailUser.length > 0
    ) {
      // signed in but not accepted
      return (
        <Router>
          <div>
            <Container fluid={true} className="AppBody">
              <Row noGutters={true}>
                <Col>
                  <Header signedInUser={signedInUser} />
                  {signedInUser.emailUser.length > 0 && (
                    <p className="SignOut">
                      {signedInUser.username}{" "}
                      <button
                        className="signOutButton"
                        onClick={() => signOut()}
                      >
                        Sign Out
                      </button>
                    </p>
                  )}
                </Col>
              </Row>
              <Row noGutters={true} className="MainRow">
                <Col xl="3" lg="3" md="4" sm="4" className="SidebarCol">
                  <Sidebar
                    setTooltip={setTooltip}
                    emailUser={signedInUser.emailUser}
                  />
                </Col>
                <Col xl="9" lg="9" md="8" sm="8" className="MainCol">
                  <Switch>
                    <Aup emailUser={signedInUser.emailUser} />
                    <Route exact strict path="/announcements">
                      {announcements(signedInUser.emailUser)}
                    </Route>
                  </Switch>
                </Col>
              </Row>
              <Row noGutters={true}>
                <Col>
                  <Footer />
                </Col>
              </Row>
            </Container>
          </div>
        </Router>
      );
    }

    if (
      signedInUser.emailUser != null &&
      signedInUser.emailUser.length > 0
    ) {
      // signed in
      return (
        <Router>
          <div>
            <Container fluid={true} className="AppBody">
              <Row noGutters={true}>
                <Col>
                  <Header signedInUser={signedInUser} />
                  {signedInUser.emailUser.length > 0 && (
                    <p className="SignOut">
                      {signedInUser.username}{" "}
                      <button
                        className="signOutButton"
                        onClick={() => signOut()}
                      >
                        Sign Out
                      </button>
                    </p>
                  )}
                </Col>
              </Row>
              <Row noGutters={true} className="MainRow">
                <Col xl="3" lg="3" md="4" sm="4" className="SidebarCol">
                  <Sidebar
                    setTooltip={setTooltip}
                    emailUser={signedInUser.emailUser}
                  />
                </Col>
                <Col xl="9" lg="9" md="8" sm="8" className="MainCol">
                  <Main
                    tooltipVisible={tooltip}
                    emailUser={signedInUser.emailUser}
                  />
                  <Route exact strict path="/announcements">
                    {announcements(signedInUser.emailUser)}
                  </Route>
                </Col>
              </Row>
              <Row noGutters={true}>
                <Col>
                  <Footer />
                </Col>
              </Row>
            </Container>
          </div>
        </Router>
      );
    }
    // default
    return (
      <Router>
        <div>
          <Container fluid={true} className="AppBody">
            <Row noGutters={true}>
              <Col>
                <Header signedInUser={signedInUser} />
                {token != null &&
                  token.length > 0 &&
                  (signedInUser.emailUser == null ||
                    signedInUser.emailUser.length === 0) ? (
                  <p className="SignOut">
                    <button className="signOutButton" onClick={() => signIn()}>
                      Sign in
                    </button>
                  </p>
                ) : (
                  <p className="SignOut">
                    Please authorise your account{" "}
                    <button
                      className="signOutButton"
                      onClick={() => getToken()}
                    >
                      Authorise
                    </button>
                  </p>
                )}
              </Col>
            </Row>
            <Row noGutters={true} className="MainRow">
              <Col xl="3" lg="3" md="4" sm="4" className="SidebarCol">
                <Sidebar
                  setTooltip={setTooltip}
                  emailUser={signedInUser.emailUser}
                />
              </Col>
              <Col xl="9" lg="9" md="8" sm="8" className="MainCol">
                <Route exact strict path="/announcements">
                  {announcements(signedInUser.emailUser)}
                </Route>
              </Col>
            </Row>
            <Row noGutters={true}>
              <Col>
                <Footer />
              </Col>
            </Row>
          </Container>
        </div>
      </Router>
    );
  }
};

export default App;
