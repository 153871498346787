import React, { Component } from "react";
import { Accordion, Card, ListGroup } from "react-bootstrap";
import arrow from "../../images/chevron-down.svg";
import rightarrow from "../../images/chevron-right.svg";
import "./sidebar.css";

class Accord extends Component {
  constructor(props) {
    super(props);
    this.state = { open: props.open, img: props.img };
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick = () => {
    this.state.open === "true"
      ? this.setState({
          open: "false",
          img: rightarrow
        })
      : this.setState({
          open: "true",
          img: arrow
        });
  };

  render() {
    const defaultDisplay = this.props.defaultActive
      ? this.props.defaultActive
      : "";

    return (
      <Accordion defaultActiveKey={defaultDisplay}>
        <Card className="MenuCard">
          <Accordion.Toggle
            as={Card.Header}
            eventKey={this.props.eventKey}
            onClick={this.handleClick}
            id={this.props.eventKey + "_collapse"}
          >
            <div className="menu-head">
              <img className="header-icon" alt="" src={this.props.icon} />
              {this.props.title}
              <img className="arrow-icon" alt="" src={this.state.img} />
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse id={`list${this.props.title}`} eventKey={this.props.eventKey}>
            <ListGroup>{this.props.content}</ListGroup>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    );
  }
}

export default Accord;
