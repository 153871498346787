import React, { Component } from "react";
import { Container, Form, Button, Table } from "react-bootstrap";
import Moment from "moment";

const axios = require("axios");

class Announcements extends Component {
  constructor() {
    super();

    this.state = {
      id: "",
      title: "",
      content: "",
      announcements: [],
    };
    this.updateRecords = this.updateRecords.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.deleteRecord = this.deleteRecord.bind(this);
    this.editRecord = this.editRecord.bind(this);
  }

  componentDidMount() {
    axios({
      method: "GET",
      url: "https://europe-west2-nihrhome.cloudfunctions.net/nihr-announcements",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        this.setState({
          announcements: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  updateRecords() {
    axios({
      method: "GET",
      url: "https://europe-west2-nihrhome.cloudfunctions.net/nihr-announcements",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        this.setState({
          announcements: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  submitForm(event) {
    axios({
      method: "POST",
      url: "https://europe-west2-nihrhome.cloudfunctions.net/nihr-announcements",
      data: this.state,
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        this.updateRecords();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  deleteRecord = (announcementID) => {
    let itemDelete = { id: announcementID };
    axios({
      method: "DELETE",
      url: "https://europe-west2-nihrhome.cloudfunctions.net/nihr-announcements",
      data: itemDelete,
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        this.updateRecords();
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  editRecord = (announcement) => {
    this.setState({
      id: announcement.id,
      title: announcement.title,
      content: announcement.content,
    });
  };

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    return (
      <div className="main-div">
        <Container>
          <br />
          <h2>Upload Announcements</h2>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th style={{ width: "10%" }}>Title</th>
                <th style={{ width: "40%" }}>Message</th>
                <th style={{ width: "35%" }}>Date</th>
                <th style={{ width: "15%" }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {this.state.announcements.map((announcement) => (
                <tr key={"announcement" + announcement.id}>
                  <td>{announcement.title}</td>
                  <td>{announcement.content}</td>
                  <td>
                    {Moment(announcement.updatedAt).format(
                      "Do MMMM YYYY hh:mma"
                    )}
                  </td>
                  <td>
                    <Button
                      size="sm"
                      block
                      onClick={() => this.deleteRecord(announcement.id)}
                    >
                      Delete
                    </Button>
                    <Button
                      size="sm"
                      block
                      onClick={() => this.editRecord(announcement)}
                    >
                      Edit
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Form>
            <Form.Group>
              <Form.Control
                id="id"
                value={this.state.id}
                name="id"
                type="hidden"
              />
              <Form.Label>Title</Form.Label>
              <Form.Control
                id="title"
                value={this.state.title}
                name="title"
                type="text"
                onChange={(e) => {
                  this.handleChange(e);
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Content</Form.Label>
              <Form.Control
                id="content"
                value={this.state.content}
                name="content"
                as="textarea"
                rows="3"
                onChange={(e) => {
                  this.handleChange(e);
                }}
              />
            </Form.Group>
            <Button onClick={() => this.submitForm()}>Submit</Button>
          </Form>
        </Container>
      </div>
    );
  }
}

export default Announcements;
