import React from "react";
import { DragSource } from "react-dnd";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "./Tools.css";

const createIcon = (icon, emptyTool, index, toolTipStatus) => {
  let favSection = [];

  favSection.push(
    emptyTool ? (
      <button
        id=""
        className="empty-space"
        target="_blank"
        rel="noopener noreferrer"
        key={index + "button"}
      >
        +
      </button>
    ) : toolTipStatus ? (
      <OverlayTrigger
        placement="top"
        delay={{ show: 0, hide: 0 }}
        key={index + "Tooltip"}
        overlay={
          <Tooltip className="icon-tooltip" id={`tooltip-${icon.id}`}>
            <p>{icon.tooltip}</p>
          </Tooltip>
        }
      >
        <a
          id=""
          href={icon.link}
          target="_blank"
          rel="noopener noreferrer"
          key={index + "Tag"}
        >
          <img className="img" alt={icon.alt} src={icon.img} />
        </a>
      </OverlayTrigger>
    ) : (
      <a
        id=""
        href={icon.link}
        target="_blank"
        rel="noopener noreferrer"
        key={index + "Tag"}
      >
        <img className="img" alt={icon.alt} src={icon.img} />
      </a>
    )
  );

  return favSection;
};

export const Icon = ({
  name,
  type,
  item,
  section,
  index,
  toolTipStatus,
  isDropped,
  isDragging,
  connectDragSource
}) => {
  const icon = item;
  const emptyTool = section === "FavTools" && !icon;
  const noDragTool = type === "empty" ? "empty" : "iconPresent";

  return connectDragSource(
    <div className={`iconButton ${noDragTool}`}>
      {/* {isDropped ? <s>{name}</s> : name} */}
      {createIcon(icon, emptyTool, index, toolTipStatus)}
      <p className="desc">{icon ? icon.desc : ""}</p>
    </div>
  );
};

export default DragSource(
  props => props.type,
  {
    beginDrag: props => ({ name: props.name })
  },
  (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  })
)(Icon);
