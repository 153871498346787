import React, { Component } from "react";
import {
  Form,
  FormControl,
  Button,
  InputGroup,
  Col,
  Row,
  Container
} from "react-bootstrap";
import "./Search.css";
const axios = require("axios");

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputTextSearch: ""
    };
  }

  handleFullNameChange = e => {
    this.setState({
      inputTextSearch: e.target.value
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.addToLocalStorage();
    window.open(
      "https://cloudsearch.google.com/cloudsearch/search?q=" +
        this.state.inputTextSearch
    );
    //document.location.reload(true);
  };

  addToLocalStorage() {
    try {
      if (this.state.inputTextSearch !== "") {
        axios({
          method: "POST",
          url:
            "https://europe-west2-nihrhome.cloudfunctions.net/nihr-user-searches",
          data: {
            userEmail: this.props.emailUser,
            searchValue: this.state.inputTextSearch,
            searchDate: new Date(),
            url:
              "https://cloudsearch.google.com/cloudsearch/search?q=" +
              this.state.inputTextSearch,
            recent: true,
            saved: false
          },
          headers: { "Content-Type": "application/json" }
        })
          .then(response => {
            console.log(response);
          })
          .catch(error => {
            console.log(error);
          });
      }
    } catch (error) {}
  }

  render() {
    return (
      <div className="Search">
        <Container>
          <Row className="justify-content-md-center">
            <Col xl="9" lg="9" md="12">
              <Form
                target="_blank"
                rel="noopener noreferrer"
                onSubmit={this.handleSubmit}
                id="search-form"
              >
                <InputGroup>
                  <FormControl
                    onChange={this.handleFullNameChange}
                    className="SearchInput"
                    placeholder="Search NIHR libraries using Cloud Search..."
                    aria-label="Search NIHRlibraries using Cloud Search..."
                    id="search-input"
                  />

                  <InputGroup.Append>
                    <Button
                      id="search-input-button"
                      className="SearchButton"
                      type="submit"
                    >
                      Search
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </Form>
              <p className="SearchText">
                Use Cloud Search to source information from selected NIHR
                libraries
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Search;
