import React, { Component } from "react";
import { Col, Row, Container } from "react-bootstrap";
import Search from "./Search/Search";
import Tools from "./Tools/Tools";

class Main extends Component {
  render() {
    return (
      <div className="main-div">
        <Container>
          <Row>
            <Col>
              <Search emailUser={this.props.emailUser} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Tools
                tooltipVisible={this.props.tooltipVisible}
                emailUser={this.props.emailUser}
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
export default Main;
