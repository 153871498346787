import React, { Component } from "react";
import SidebarList from "./sidebarList";
import externalLink from "../../images/external-link.svg";
import { Card } from "react-bootstrap";
import "./sidebar.css";

class Sidebar extends Component {
  render() {
    if (null != this.props.emailUser && this.props.emailUser.length > 0) {
      return (
        <div className="SideBar">
          <SidebarList
            setTooltip={this.props.setTooltip}
            emailUser={this.props.emailUser}
          />
        </div>
      );
    } else {
      return (
        <div className="sidebar">
          <Card className="TitleCard">
            <Card.Header>
              <a
                className="support-link"
                href="https://support.nihr.ac.uk/nihr/login_with_sso.do?glide_sso_id=771b6fbe1b215110f9512060f54bcb04"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="support">
                  Go to Support
                  <img
                    className="right-chev"
                    alt="support page"
                    src={externalLink}
                  />
                </div>
              </a>
            </Card.Header>
          </Card>
        </div>
      );
    }
  }
}
export default Sidebar;
