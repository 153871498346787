import React, { Component } from "react";
import { Navbar, Col } from "react-bootstrap";
import logo from "../../images/nihrlogo.png";
import "./header.css";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSignedIn: this.props.signedInUser.status,
      userDetails: {},
      userName: this.props.signedInUser.userName,
      emailUser: this.props.signedInUser.emailUser,
    };
  }

  componentDidUpdate(state, props) {
    if (this.state.userName !== this.props.signedInUser.userName) {
      this.setState({
        isSignedIn: true,
        emailUser: this.props.signedInUser.emailUser,
        userName: this.props.signedInUser.userName,
      });
    }
  }

  onSuccess() {
    this.setState({
      isSignedIn: true,
      err: null,
    });
    this.props.setEmail(this.state.emailUser);
  }

  onLoginFailed(err) {
    this.setState({
      isSignedIn: false,
      error: err,
    });
  }

  getContent() {
    if (
      this.state.emailUser != null &&
      this.state.emailUser.length === 0 &&
      !this.state.isSignedIn
    ) {
      return <></>;
    }
  }

  signOut() {
    localStorage.removeItem("signedInUser");
    window.location.href =
      "https://accounts.google.com/a/nihr.ac.uk/Logout?service=ah&passive=true&continue=https://appengine.google.com/_ah/conflogin%3Fcontinue%3D" +
      window.location.href;
  }
  render() {
    return (
      <Navbar className="nav" expand="lg">
        <Col xl="3" lg="3" md="4" sm="6" xs="12">
          <Navbar.Brand
            className="HeaderBrand"
            href="https://www.nihr.ac.uk/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="logo" alt="NIHR homepage" src={logo} />
          </Navbar.Brand>
        </Col>

        <Col xl="9" lg="9" md="8" sm="6" xs="12" className="HeaderTitle">
          <Navbar.Text className="Title">
            <h1>Welcome to the NIHR Hub</h1>
          </Navbar.Text>
          <Navbar.Text>
            {this.getContent()}
            {/* <img src={profile} /> */}
          </Navbar.Text>
        </Col>
      </Navbar>
    );
  }
}

export default Header;
